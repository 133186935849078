@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700&display=swap");
@import url('https://fonts.cdnfonts.com/css/circular-std');

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */
    font-family: 'Poppins', sans-serif;     
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.file-input {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.file-input input[type="file"] {
  display: none;
}

.file-icon {
  font-size: 6rem;
  color: #aaaaaa;
  cursor: pointer;
}

.file-input label {
  display: block;
  position: relative;
  width: 200px;
  height: 50px;
  border-radius: 25px;
  font-size: 1rem;
  background: linear-gradient(60deg, #dd5e89, #f7bb97);
  box-shadow: 0 4px 7px rgba(0, 0, 0, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-weight: bold;
  cursor: pointer;
  transition: transform 0.2s ease-out;
}

.file-input label:hover,
input:focus {
  transform: scale(1.02);
}

input:focus + label {
  outline: 1px solid #000;
  outline: -webkit-focus-ring-color auto 2px;
}

.link {
  color: #999999 !important;
}

/* a.active {
  color: #506ee4 !important;
} */
.sidebar-dropdown {
  background-color: #363e46;
  list-style-type: none;
}

.sidebar-dropdown li {
  margin-top: 0 !important;
}

.sidebar-dropdown li a {
  font-size: 12px !important;
}

/* Payment Receipt Design */

.receipt {
  margin: 0 auto;
  min-height: 100vh;
  width: 50vw;
  background-color: #fff;
  font-family: "Poppins";
  color: #0c0f2e;
  position: relative;
  padding: 2rem 4rem 2rem 4rem;
}

.receipt .contents {
  position: relative;
  z-index: 5;
}

.receipt:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  /* background-image: url('assets/images/dsp_watermark.png'); */
  background-size: 1000px;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  opacity: .5;
  z-index: 2;
}

.receipt-header {
  width: 100%;
}

.receipt-header .school-title {
  display: flex;
  justify-content: center;
  align-items: center;
  /* border-bottom: 2px solid #0e0e0e; */
}

.receipt-header .school-title .logo {
  height: 70px;
  margin-right: 1.5rem;
}

.receipt-header .school-title .name {
  font-weight: 700;
  font-size: 1.8rem;

}

.subheader {
  text-align: center;
  font-size: 1rem;
  text-transform: uppercase;
  font-weight: 500;
  margin-top: 0.5rem;
}
.subtitle {
  text-align: center;
  font-size: 1rem;
  text-transform: uppercase;
  font-weight: 500;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
  color: #fff;
}

.subheader span {
  font-style: italic;
  font-weight: 600;
  text-transform: none;
}

.receipt-body {
  margin-top: 4rem;
}

.body-header {
  display: flex;
  justify-content: space-between;
}

.body-header .passport {
  height: 140px;
  width: 120px;
  margin-right: 2rem;
}
.body-header .qr-code {
  height: 100px;
  width: 100px;
  margin-right: 2rem;
}

.body-header .passport img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.body-header .qr-code img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.user-details {
  width: 50%;
  margin-right: 1rem;
}
.user-details div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.user-details-option {
  font-weight: 600;
  font-size: 14px;
  margin-bottom: 0;
}

.user-details-info {
  font-weight: 400;
  font-size: 14px;
  margin-bottom: 0;
}
.user-details-option div {
}
.body-info {
  width: 100%;
  margin-top: 1rem;
  /* padding-left: 2rem; */
}

.body-info div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;

}

.multiple-option div {
  display: flex;
  flex-direction: column;
  border-bottom: none;
}

.receipt-footer {
  display: flex;
  width: 100%;
  justify-content: space-between;
  font-size: 10px;
  margin-top: 5rem;
  border-top: 1px solid #0c0f2e;
}

.fw-600 {
  font-weight: 600;
}
table {
  font-size: 14px;
  border-collapse: collapse;
  width: 100%;
}

td, th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}
th{
  font-weight: 600;
}
td {
  font-weight: 400;
}

tr:nth-child(even) {
  background-color: #F8F8F8;
}

.print-button {
  margin-top: 2rem;
}
.print-button button {
  font-family: 'poppins';
  font-size: 14px;
  padding: 0.2rem 3rem;
  cursor: pointer;
  border-radius: 20px;
  border: none;
  color: #fff;
  transition: all ease-in 0.3s;
}

.print-button button:hover {
  background: #00A3FF;
}

.dspg-danger { 
    color: #721c24;
    background-color: #f8d7da;
    border-color: #f5c6cb;
}
.dspg-dark {
  color: #1b1e21;
  background-color: #d6d8d9;
  border-color: #c6c8ca;
}
.dspg-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}
.dspg-warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba;
}
.dspg-info {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb;
}

@media print {
  .receipt {
    margin: 0 auto;
    min-height: 100vh;
    width: 100%;
    background-color: #fff;
    font-family: "Poppins";
    color: #0c0f2e;
    position: relative;
    padding: 2rem 4rem 2rem 4rem;
    -webkit-print-color-adjust: exact;
  }
  
  .receipt .contents {
    position: relative;
    z-index: 5;
  }
  
  .receipt:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    /* background-image: url('assets/images/dsp_watermark.png') !important; */
    background-size: 1000px;
    background-position: center;
    background-repeat: no-repeat;
    background-image: none;
    width: 100%;
    height: 100%;
    opacity: .5;
    z-index: 2;
  }
  
  .receipt-header {
    width: 100%;
  }
  
  .receipt-header .school-title {
    display: flex;
    justify-content: center;
    align-items: center;
    /* border-bottom: 2px solid #0e0e0e; */
  }
  
  .receipt-header .school-title .logo {
    height: 70px;
    margin-right: 1.5rem;
  }
  
  .receipt-header .school-title .name {
    font-weight: 700;
    font-size: 1.8rem;
  
  }
  
  .subheader {
    text-align: center;
    font-size: 1rem;
    text-transform: uppercase;
    font-weight: 500;
    margin-top: 0.5rem;
  }

  .subtitle {
    text-align: center;
    font-size: 1rem;
    text-transform: uppercase;
    font-weight: 500;
    margin-top: 0.5rem;
    margin-bottom: 1rem;
    color: #fff;
    width: 100%;
    border-bottom: 1px solid #0c0f2e;
    border-top: 1px solid #0c0f2e;
  }
  
  .subheader span {
    font-style: italic;
    font-weight: 600;
    text-transform: none;
  }
  
  .receipt-body {
    margin-top: 4rem;
  }
  
  .body-header {
    display: flex;
    justify-content: space-between;
  }
  
  .body-header .passport {
    height: 140px;
    width: 120px;
    margin-right: 2rem;
  }
  
  .body-header .passport img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
  
  .user-details {
    width: 50%;
  }
  .user-details div {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .user-details-option {
    font-weight: 600;
    font-size: 14px;
    margin-bottom: 0;
  }
  
  .user-details-info {
    font-weight: 400;
    font-size: 14px;
    margin-bottom: 0;
  }
  .body-info {
    width: 100%;
    margin-top: 1rem;
  }
  
  .body-info div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5px;
  
  }
  
  
  .multiple-option div {
    display: flex;
    flex-direction: column;
    border-bottom: none;
  }
  
  .receipt-footer {
    display: flex;
    width: 100%;
    justify-content: space-between;
    font-size: 10px;
    margin-top: 5rem;
    border-top: 1px solid #0c0f2e;
  }
  
  .fw-600 {
    font-weight: 600;
  }

  .print-button {
    display: none;
  }
}